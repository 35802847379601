import React from 'react'
import Gallery from 'react-image-gallery';
import { GalleryWrapper } from './Gallery.css';

export default function ImageGallery({ data }) {

  const items = data.images.map(transform)

  return (
    <GalleryWrapper>
      <Gallery items={items} showThumbnails={true} showIndex={true} thumbnailPosition='bottom' ></Gallery>
    </GalleryWrapper>
  )
}

function transform(item) {
  return {
    original: item.reference,
    thumbnail: item.reference,
    originalAlt: item.alt,
    originalTitle: item.title,
    lazyLoad: true,
    thumbnailWidth: "300px"
  }
}