import React from "react";
import { graphql, Link } from "gatsby";
// import 'react-image-gallery/styles/css/image-gallery.css';
// import ImageGallery from 'react-image-gallery';
import { FormattedMessage } from "react-intl";
import Layout from "../components/Layout";
import Head from "../components/Layout/Head";
import Layouts from "./house/Layouts";
import { Container } from "./house/house.css";
import BukazuCalendar from "./house/BukazuCalendar";
import ImageGallery from "./house/Gallery";
import HouseMap from "./house/HouseMap";
import BackgroundImage from "./house/BackgroundImage";
import HouseNavigation from "./house/HouseNavigation";
import Description from "./house/Description";
import Overview from "./house/Overview";
import SideBar from "./house/SideBar";

class HouseTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data_fields: JSON.parse(props.data.buroBorkHouse.field),
      locale: props.pageContext.locale
    };
  }

  renderBukazuCalendar() {
    const { data_fields, locale } = this.state;
    return <BukazuCalendar house={data_fields} locale={locale} />;
  }

  scrollTo(element) {
    let elem = document.getElementById(element);
    elem.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }


  render() {
    const { data_fields, locale } = this.state;
    const { data, path } = this.props;
    const site = JSON.parse(data.buroBorkSite.field);

    let img;
    if (data_fields.images[0]) {
      if (data_fields.images[0].reference) {
        if (data_fields.brand === "BUKAZU") {
          img = data_fields.images[0].reference;
        } else {
          img =
            "https://cms.burobork.nl/images/" + data_fields.images[0].reference;
        }
      } else {
        if (data_fields.brand === "LEISURE") {
          img = "https://" + data_fields.images[0].preview;
        } else {
          img = data_fields.images[0].preview;
        }
      }
    }

    const hrefLangs = site.site_settings.locales.map(locale => {
      if (locale !== site.site_settings.default_locale) {
        return { lang: locale, path: `/${locale}${path}` };
      }
      return { lang: locale, path: path };
    });

    return (
      <Layout locale={locale}>
        <Head
          imageUrl={img}
          pageTitle={data_fields.name}
          pageDescription={data_fields[`short_description_${locale}`]}
          hrefLangs={hrefLangs}
        />
        <BackgroundImage image={img} title={data_fields.name} city={data_fields.city}>

        </BackgroundImage>
        <Container>
          <div className='main-house'>


            <HouseNavigation scrollTo={this.scrollTo} data_fields={data_fields} />


            <Overview data_fields={data_fields} locale={locale} default_locale={site.site_settings.default_locale} />
            <div id="photos" >
            </div>
            <ImageGallery
              data={data_fields}
            />


            <div className="description" id="description">
              <Description data_fields={data_fields} locale={locale} />
            </div>
            <div className="properties" id="map">
              <h2>
                <FormattedMessage id="map" />
              </h2>
              <HouseMap
                latitude={Number(data_fields.latitude)}
                longitude={Number(data_fields.longitude)}
                isMarkerShown={true}
              />
            </div>
            <div className="properties" id="properties">
              <h2>
                <FormattedMessage id="properties" />
              </h2>
            </div>
            <div className="properties section">
              <Layouts
                properties={data_fields.layout_properties}
                locale={locale}
                house_props={data_fields.house_properties}
              />
            </div>
            <div className="properties section" id="book">
              <h2>
                <FormattedMessage id="book" />
              </h2>
              <div style={{ width: "100%" }}>
                <BukazuCalendar house={data_fields} locale={locale} />
              </div>
            </div>
          </div>
          <SideBar locale={locale} data_fields={data_fields} scrollTo={this.scrollTo} settings={site.site_settings}
          >
          </SideBar>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query BuroBorkHousebySlug($id: String!) {
    buroBorkSite {
      id
      field
      settings {
        default_locale

      }
    }
    buroBorkHouse(id: { eq: $id }) {
      id
      field
    }
  }
`;
export default HouseTemplate;
