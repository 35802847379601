import React from "react";
import { FormattedMessage } from "react-intl";
import HouseMenu from "./houseMenu";
import styled from "styled-components";
import MEDIA from "../../helpers/mediaTemplates";

export default function HouseNavigation({ scrollTo, data_fields }) {

  let links = ["photos", "description", "map", "properties", "book"];

  return (
    <>
    <HouseMenu data_fields={data_fields} scrollTo={scrollTo} />
    <HouseNav>
      <ul>
        {links.map(link => (

              <li
                onClick={() => scrollTo(link)}
                role="presentation"
                onKeyPress={() => scrollTo(link)}
                key={link}
              >
                <FormattedMessage id={link} />
              </li>
        ))}

            </ul>
    </HouseNav>
    </>
  )
}

const HouseNav = styled.div`
  padding: 8px;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  color: #3a3a3a;
  font-size: 2rem;
  color: ${props => props.theme.primaryColor};
  ul {
    display: flex;
    align-self: flex-end;
    flex-wrap: wrap;
    ${MEDIA.TABLET`
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      `};
    li {
      padding: 16px;
      cursor: pointer;
      font-weight: 700;
    }
  }
`;