import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  width: 100%;
  padding: 8px 0 8px;
  display: flex;
  align-items: center;
  position: relative;
  &:before {
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    content: '';
    background: linear-gradient(90deg, #e2e2e2, rgba(255, 255, 255, 0.1));
  }
  .title {
    display: block;
    width: 10%;

  }
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      /* padding: 4px; */
      flex: 1 1;
      &:first-letter {
        text-transform: capitalize;
      }
      min-width: 120px;
    }
  }
`;

export const Group = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${props => props.theme.secondaryColor};
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  .parent-title {
    font-weight: 300;
    font-size: 1.8rem;
    padding: 16px 32px;
  }
  .parent {
    padding: 0 0 0 16px;
    columns: 5;
    font-weight: 500;
    font-size: 1.6rem;
    ${MEDIA.TABLET`
      columns: unset;
    `}

    .child {
      padding: 8px 0;
    }
    .grand-child {
      padding: 4px 0;
    }
  }
`;

export const GroupContainer = styled.section`
  width: 100%;
  padding: 16px 0;
`;

export const Collapse = styled.div`
  max-height: 250px;

`