import React from "react";
import styled from "styled-components";
import MEDIA from '../../helpers/mediaTemplates'

export default function BackgroundImage({ image, title, city }) {
  return (
    <BackgroundImageContainer>
      <img src={image} />
      <BackgroundImageInner>
        <div className="content">

      <h1>{title}</h1>
      <h2>
        <span>

        {city}
        </span>
        </h2>
        </div>
      </BackgroundImageInner>
    </BackgroundImageContainer>
  )
}

export const BackgroundImageContainer = styled.div`
  position: relative;
  height: 600px;
  overflow: hidden;
  background-color: rgba(0,0,0,.2);
  ${MEDIA.TABLET`
    height: 400px;
  `}
  img {
    position: absolute;
    top: 50%;
    min-height: 100%;
    transform: translate(0, -50%);
    z-index: -1;
  }

`

const BackgroundImageInner = styled.div`
position: absolute;
left: 0;
width: 100%;
height: 100%;
.content {
  max-width: 1440px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

    h1 {
      color: #fff;
      font-size: 6.4rem !important;
      text-shadow: 1px 1px #000;
      ${MEDIA.TABLET`
        font-size: 4.8rem !important;
      `}
    }
    h2 {
      span {
        background: #fff;
        padding: 4px 8px;
      }
      mix-blend-mode: screen;
      font-size: 2rem;
      ${MEDIA.TABLET`
        font-size: 1.8rem !important;
      `}
      padding: 4px;
      display: inline;
    }
  }
`