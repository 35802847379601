import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import MEDIA from "../../helpers/mediaTemplates";
import { generateArrayOfNumbers } from "./util";
import { Star } from "../../icons";
import { Link } from "gatsby";
import RegionUrl from "../../helpers/regionUrlGenerator";

function Overview({ data_fields, locale, default_locale }) {
  const stars = generateArrayOfNumbers(data_fields.stars + 1);
  const hasRegion = data_fields.region ? true : false;
  const regionUrl =  hasRegion ? RegionUrl(data_fields.region.name, locale, default_locale) : "";
  return (
    <OverviewContainer>
      <div className="overview-item">
        <div className="overview-item-text">
          <FormattedMessage id="stars" />
        </div>
        <div className="overview-item-number">
          <div className="stars">
            {stars.map((index) => {
              return (
                <div className="star" key={index}>
                  <Star height="28" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="overview-item">
        <div className="overview-item-text">
          <FormattedMessage id="persons" />
        </div>
        <div className="overview-item-number">
          {data_fields.persons}
        </div>
      </div>
      <div className="overview-item">
        <div className="overview-item-text">
          <FormattedMessage id="bedrooms" />
        </div>
        <div className="overview-item-number">
          {data_fields.bedrooms}
        </div>
      </div>
      <div className="overview-item">
        <div className="overview-item-text">
          <FormattedMessage id="bathrooms" />
        </div>
        <div className="overview-item-number">
          {data_fields.bathrooms}
        </div>
      </div>
      {/* {hasRegion && (

        <div className="overview-item">
          <div className="overview-item-text">
            <FormattedMessage id="region" />
          </div>
          <div className="overview-item-number">
            <Link to={regionUrl}>
              {data_fields.region.name}
            </Link>
          </div>
        </div>
      )} */}
    </OverviewContainer>
  );
}

Overview.defaultProps = {
  default_locale: "nl"
};

const OverviewContainer = styled.div`
display: flex;
align-items: center;

${MEDIA.TABLET`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  `}
  & > div,
.overview-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px 32px;
  border-right: .5px solid #cacaca;

  &:last-of-type {
    border-right: 0;
  }
  ${MEDIA.TABLET`
    padding: 0 8px;
    flex-direction: row;
    border-right: 0;
  `};
}
.overview-item {
  &-number {
    font-size: 1.6rem;

  }
  &-number {
    padding: 8px;
    font-size: 2.8rem;
    font-weight: 500;
  }
  svg {
    fill: ${props => props.theme.primaryColor};
  }
}
.stars {
  display: flex;
  align-items: flex-start;
  height: 100%;
}
`;

export default Overview;