import React from "react";
import Portal from "bukazu-portal-react";
import "bukazu-portal-react/build/index.css";

const BukazuCalendar = ({ house, locale }) => {
  let house_code = house.house_code;

  if (typeof window !== `undefined`) {
    return (
      <div style={{ padding: "16px" }} id="bukazu-app">
        <Portal
          id="bukazu-app"
          className="bukazu-app"
          portalCode={process.env.GATSBY_PORTAL_CODE}
          objectCode={house_code}
          locale={locale}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default React.memo(BukazuCalendar);
