import React, { useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { ArrowRight, Email } from "../../icons";
import { SocialIcon } from "react-social-icons";
import MEDIA from "../../helpers/mediaTemplates";


function SideBar({ data_fields, locale, scrollTo, settings }) {
  var page_url = '';
  if (typeof window !== `undefined`) {
    page_url = encodeURIComponent(window.location.href);
  }
  return (
    <SideBarContainer>
      <div className="picker" onClick={() => scrollTo('book')}
                  role="presentation"
                  onKeyPress={() => scrollTo('book')}>
                    <FormattedMessage id="available_dates"></FormattedMessage>
                    <ArrowRight width={20} />

      </div>
      {/* <div className="contact">
        <h3><FormattedMessage id="contact"></FormattedMessage></h3>
        <div dangerouslySetInnerHTML={{ __html: settings.housePageContact[locale] }}></div>
      </div> */}
      <div className="social-share">
        <h3>
          <FormattedMessage id="share"></FormattedMessage>
        </h3>
        <ul>
          <li className="title">
          </li>
          <li key="facebook">
            <SocialIcon network="facebook" url={`https://www.facebook.com/sharer.php?u=${page_url}`} />
          </li>
          <li>
            <SocialIcon network="twitter" url={`https://twitter.com/share?url=${page_url}&text=${data_fields.name}`} />
          </li>
          <li>
            <SocialIcon network="linkedin" url={`https://www.linkedin.com/shareArticle?url=${page_url}&title=${data_fields.name}`} />
          </li>
          <li>
            <SocialIcon network="whatsapp"  url={`https://api.whatsapp.com/send?text=${data_fields.name} ${page_url}`} />
          </li>
          <li className="email">
            <a
              key="mail"
              href={`mailto:?subject=${data_fields.name}&body=Check out this site: ${page_url}`}
            >
              <Email width={45} />
            </a>
          </li>
        </ul>
      </div>

    </SideBarContainer>
  )
}

const SideBarContainer = styled.section`
  ${MEDIA.TABLET`
    display:none;
  `}
  width: 400px;
  margin: 0 0 0 32px;
  padding: 32px;
  .picker {
    position: relative;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 2rem;
    text-align: center;


    &:before {
      content: '';
      position: absolute;
      background-color: ${props => props.theme.secondaryColor};
      width: 100%;
      height: 100%;
      opacity: .5;
      z-index: -1;
      transition: .3s all ease-in;
    }

    &:hover {
      &:before {
        opacity: .3;
      }
    }
  }
  .contact {
    margin: 16px 0;
    padding: 16px;
    position: relative;
    div {
      padding: 8px 0;
      font-size: 1.8rem;
    }
    &:before {
      content: '';
      position: absolute;
      background-color: ${props => props.theme.primaryColor};
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .5;
      z-index: -1;
      transition: .3s all ease-in;
    }
  }
  .social-share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0 0;
    ul {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
      }
    }
  }
`

export default SideBar;