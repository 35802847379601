import React, { useState } from 'react';
import { Group, GroupContainer } from './properties.css';
import { FormattedMessage } from 'react-intl';
import { DescriptionContainer } from './Description';

const Layouts = ({ properties, house_props, locale }) => {
  const [show, setShow] = useState(false)

  const filterProps = prop => {
    return properties.filter(
      o => o.parent_id === prop.layout_id && o.parent_sequence === prop.sequence
    );
  };

  let groups = properties.map(prop => {
    if (!prop.parent_id) {
      let children = filterProps(prop);
      return <Group id={prop.id} key={prop.id}>
          <strong>{prop.titles[locale]}</strong>
          <div className="parent">
            {children.map(child => {
              let grandChildren = filterProps(child);
              return <div className="child" id={child.id} key={child.id}>
                  {child.titles[locale]}
                  <div className="parent">
                    {grandChildren.map(grandChild => (
                      <div
                        className="grand-child"
                        id={grandChild.id}
                        key={grandChild.id}
                      >
                        {grandChild.titles[locale]}
                      </div>
                    ))}
                  </div>
                </div>;
            })}
          </div>
        </Group>;
    } else {
      return null;
    }
  });

  return (
    <DescriptionContainer className={show ? "show_all" : ""} id='desc_container'>
      <GroupContainer>
        <Group>
          <div className="parent-title">
              <FormattedMessage id="general" />
          </div>
          <div className="parent">
            {house_props.map(prop => (
              <div className="child" key={prop.id} id={prop.id}>
                {prop.titles[locale]}
              </div>
            ))}
          </div>
        </Group>
        {groups}
      </GroupContainer>
      {groups.length > 0 && (

        <div className="overlay">
          <div onClick={() => setShow(!show)} className='overlay__button'>
            {!show && (
              <FormattedMessage id="show_all" />
            )}
            {show && (
              <FormattedMessage id="hide" />
            )}
          </div>
        </div>
        )}
    </DescriptionContainer>
    );
};

export default Layouts;
