import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import MEDIA from "../../helpers/mediaTemplates";

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: ${(props) => (props.show ? "0px" : "-500px")};
  left: 0;
  background: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 99999;
  transition: all 0.5s ease;
  .house-menu--inner {
    max-width: 1440px;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--name {
      color: ${(props) => props.theme.primaryColor};
      font-weight: bold;
      line-height: 1.2;
      font-size: 2.2rem;
    }
  }
  .menu {
    display: flex;
    ${MEDIA.TABLET`
      display: none;
    `} li {
      a {
        text-decoration: none;
        font-weight: 500;
      }
      margin: 0 8px;
      cursor: pointer;
    }
  }
`;

class HouseMenu extends React.Component {
  state = { showed: false };

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    let show;
    if (scrolled > 0.1) {
      show = true;
    } else {
      show = false;
    }

    this.setState({
      theposition: scrolled,
      showed: show,
    });
  };

  render() {
    const { data_fields, scrollTo } = this.props;

    let links = ["photos", "description", "map", "properties", "book"];

    return (
      <Container show={this.state.showed}>
        <div className="house-menu--inner">
          <div
            className="house-menu--inner--name"
            dangerouslySetInnerHTML={{ __html: data_fields.name }}
          />
          <ul className="menu">
            {links.map((link) => (
              <li key={link}>
                <a
                  href={"#" + link}
                  onClick={() => scrollTo(link)}
                  onKeyPress={() => scrollTo(link)}
                >
                  <FormattedMessage id={link} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    );
  }
}

export default HouseMenu;
