import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  max-width: 1440px;
  margin: -40px auto 16px;
  background: #fff;
  position: relative;
  z-index: 99;
  min-height: 900px;
  padding: 0 16px;
  display: flex;
  h2 {
    font-size: 2.6rem;
    padding: 16px 0 8px;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.primaryColor};

  }
  a {
    color: ${props => props.theme.primaryColor};
  }
  button.show {
    border: 1px solid ${props => props.theme.primaryColor};
    background: #fff;
    padding: 8px 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      fill: ${props => props.theme.primaryColor};
      stroke: ${props => props.theme.primaryColor};
    }
  }
  .description {
    font-family: 'Gilroy', Geneva, sans-serif;
    max-width: 80ch;
    p,
    .text-block,
    .layout {
      white-space: pre-wrap;
      font-size: 1.6rem;
      line-height: 1.4;
      font-family: 'Gilroy', Geneva, sans-serif;
    }
    .overview {
      padding: 16px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      right: 64px;
      border: 2px solid ${props => props.theme.primaryColor};
      justify-content: space-between;

      @media (min-width: 1200px) {
        position: absolute;
        min-width: 300px;
      }

      &-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
      }
    }
  }
  .properties {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .section {
    position: relative;
    h2 {
      width: 100%;
    }
    &:before {
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      content: '';
      /* background: linear-gradient(90deg, #e2e2e2, rgba(255, 255, 255, 0.1)); */
    }
  }
  .image-gallery {
    &-swipe {
      box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.25);
    }
    &-slide-wrapper {
      &.right {
        width: calc(100% - 250px);
        ${MEDIA.TABLET`
              width: 100%;
            `};
      }
    }
    &-thumbnails-wrapper {
      &.right {
        ${MEDIA.TABLET`
              display: none;
            `};
        width: 240px;
        .button {
          width: 100%;
        }
      }
    }
    &-thumbnail.active {
      border-color: ${props => props.theme.primaryColor};
    }
    ${MEDIA.TABLET`
            max-width: 100%;
           `} .image-gallery-fullscreen-button.active {
      bottom: auto;
      background-color: #000;
      &:before {
        content: '\\274c';
      }
    }
  }
`;

export const ReviewsContainer = styled.div`
  .review {
    margin: 32px 0;
    &-title {
      font-weight: bold;
    }
    &-content {
      max-width: 75ch;
      padding: 16px;
      margin: 8px 0 16px 8px;
      border: 1px solid ${props => props.theme.secondaryColor};
      border-radius: 0px 16px 16px 16px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    }
  }
`;
