import React, { memo } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
const isClient = typeof window !== 'undefined';

function HouseMap({ latitude, longitude }){
  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude)
  }


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBzk-kIBt4nRqkNiIO4pCF1YNSPME5z9zE"
  })

  return (
    <div style={{ height: '500px', width: '100%' }}>
        {isClient && isLoaded ? (
      <GoogleMap
        mapContainerStyle={{ height: '500px', width: '100%' }}
        center={center}
        zoom={10}
      >
            <Marker
              position={center}
              icon="https://cms.burobork.nl/marker.svg"
            />
          </GoogleMap>
        ) : <></>}
      </div>
  );
}

export default memo(HouseMap);
