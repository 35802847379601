import React from 'react';

export function generateArrayOfNumbers (numbers) {
  return [...Array(numbers).keys()].slice(1)
}

export function renderFullscreenButton(onClick, isFullscreen) {
  return (
    <button
      type="button"
      className={`image-gallery-fullscreen-button${
        isFullscreen ? ' active' : ''
      }`}
      onClick={onClick}
    />
  );
}